import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby-link"
import styled from "styled-components"
import IconLock from "../icons/icon-lock"

const Component = styled.div`
  padding: 2em 1em;
  h2 {
      padding: 0 0 1em 0;
  }
  svg {
    fill: $medium;
  }
  input, textarea {
        font-size: 1.25rem;
        padding: 10px;
        border-radius: 5px;
        margin: 0.5em auto 1em auto;
        box-sizing: border-box;   
        font-family: sans-serif;
        width: 100%;
    }
    textarea {
        height: 10em;
        margin-bottom: 0;
    }
    .cta-text {
        text-align: left;
    }
    ul {
        margin: 0;
    }
`


function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const ContactForm = () => {
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (
        <form
            name="contact"
            method="post"
            action="/thankyou/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
        >
            <Component id="contact-form">
                <h1 className="text-invert">Send a message</h1>
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" aria-label="form-name"/>
                <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleChange} aria-label="bot-field"/>
                    </label>
                </p>
                <div class="sub-cols-1-1">
                    <div>
                        <label for="name">
                            Your name, please?
                            <input type="text" name="name" onChange={handleChange} aria-label="name" required/>
                        </label>
                    </div>
                    <div>
                        <label for="email">
                            Your email for me to reply to
                            <input type="email" name="email" onChange={handleChange} aria-label="email" required/>
                        </label>
                    </div>
                </div>
                <div>
                    <label for="message">
                        Write a message (be as detailed as you want)
                        <textarea name="message" onChange={handleChange} aria-label="message" required/>
                    </label>
                </div>
                <div class="cta-button">
                    {/* <button type="submit">Send Now</button> */}
                <button type="submit" className="cta-button">
                    <span className="cta-circle" aria-hidden="true">
                        <span className="icon arrow"></span>
                    </span>
                    <span className="cta-text">Send Message</span>
                </button>
                </div>
                <ul class="has-inline-svg"><li><IconLock/><p className="small-text">Your information will be protected and secured as per my <Link to="/privacy">Privacy Policy</Link>.</p></li></ul>
            </Component>
        </form>
    )
}

export default ContactForm

