import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"
import ContactChat from "../components/contact-chat"

const ContactPage = () => (
    <Layout>
        <SEO title="Contact" description="Contact me today and we'll start to work on your world-class custom website."/>
        <div className="cols-1-1">
            <div>
                <h3 class="h3-on-h1">Contact</h3>
                <h1>Tell me about your web design needs and aspirations today.</h1>
                <p>Let’s discuss your business challenges, plans, and how we can work together to grow your brand. Let me know your timeline and budget too.</p>
                <p>I happily reply to everyone!</p>
                <p>Email me at <strong><a href="mailto:hello@nadzim.com">hello@nadzim.com</a></strong> or fill in the form below.</p>
            </div>
        </div>
        <div className="cols-2-1 section">
            <ContactForm/>
        </div>
        <div className="cols-2-1">
            <div>
                <h1>Start a chat</h1>
                <p>Prefer a casual chat instead? Select your preferred messaging app to start a chat with me directly.</p>
            <ContactChat/>
            </div>
        </div>
        <div>
        </div>
    </Layout>
)

export default ContactPage