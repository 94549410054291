import React from "react"
import styled from "styled-components"
import IconChat from "../icons/icon-chat"

const Component = styled.ul`  
    max-width: 1296px;
    margin: 2em auto;
    list-style: none;
    padding-left: 0;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        padding: 1em;
        border-radius: 5px;
    }
    svg {
        width: 2.5em;
        margin-right: 1em;
    }
    h3, p {
        margin: 0;
        font-size: 1rem;
    }
    a {
        text-decoration: none;
    }
`

const ContactChat = () => (
    <Component id="contact-chat">
        <a href="https://m.me/nadzimwebdesign">
            <li>
            <IconChat/>
                <p><h3>Facebook Messenger</h3>@nadzimwebdesign</p>
            </li>
        </a>
        <a href="https://t.me/nadzimalrash"><li>
            <IconChat/>
            <p><h3>Telegram</h3>@nadzimalrash</p>
        </li></a>
        <a href="https://api.whatsapp.com/send?phone=60128966663"><li>
            <IconChat/>
            <p><h3>WhatsApp</h3>+6012-8966663</p>
        </li></a>
        <a href="sms:60128966663"><li>
            <IconChat/>
            <p><h3>iMessage / SMS</h3>+6012-8966663</p>
        </li></a>
    </Component>
)

export default ContactChat

